import React from 'react';
import { useTheme } from 'next-themes';
import { Toaster as Sonner } from 'sonner';
import { CheckIcon } from 'lucide-react';

type ToasterProps = React.ComponentProps<typeof Sonner>;

export const Toaster = ({ ...props }: ToasterProps) => {
    const { theme = 'system' } = useTheme();

    return (
        <Sonner
            theme={theme as ToasterProps['theme']}
            className="toaster group"
            position="bottom-center"
            duration={5000}
            toastOptions={{
                style: {
                    gridTemplateColumns: '1.5rem 1fr auto auto',
                    // @ts-expect-error `We need a CSS variable here`
                    '--toast-button-margin-start': '0',
                },
                classNames: {
                    toast: 'group toast group-[.toaster]:grid group-[.toaster]:pl-7 md:group-[.toaster]:pl-14 group-[.toaster]:pr-5 group-[.toaster]:py-6 group-[.toaster]:bg-gray-100/85 group-[.toaster]:rounded-full group-[.toaster]:backdrop-blur group-[.toaster]:text-gray-900 group-[.toaster]:shadow-lg group-[.toaster]:justify-center',
                    title: 'group-[.toast]:text-sm md:group-[.toast]:text-lg group-[.toast]:font-normal group-[.toast]:tracking-[0.065rem]',
                    description: 'group-[.toast]:text-muted-foreground',
                    icon: 'group-[.toast]:size-6',
                    actionButton:
                        'group-[.toast]:justify-self-end group-[.toast]:rounded-full group-[.toast]:justify-center group-[.toast]:px-6 group-[.toast]:py-2.5 group-[.toast]:bg-brick-50 group-[.toast]:text-primary-foreground group-[.toast]:text-base group-[.toast]:font-medium group-[.toast]:tracking-normal group-[.toast]:h-unset md:group-[.toast]:min-w-[12.5rem] group-[.toast]:hover:bg-brick-200',
                    cancelButton:
                        'group-[.toast]:justify-self-end group-[.toast]:rounded-full group-[.toast]:justify-center group-[.toast]:px-6 group-[.toast]:py-2.5 group-[.toast]:bg-gray-200 group-[.toast]:text-gray-900 group-[.toast]:text-base group-[.toast]:font-normal group-[.toast]:tracking-normal group-[.toast]:h-unset md:group-[.toast]:min-w-[12.5rem] group-[.toast]:hover:bg-gray-300',
                },
            }}
            icons={{
                success: <CheckIcon className="size-6" strokeWidth={2} />,
            }}
            style={{
                // @ts-expect-error `We need a CSS variable here`
                '--width': 'min(56.125rem, 90dvw)',
                '--offset': '2.5rem',
            }}
            {...props}
        />
    );
};
