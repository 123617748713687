import { Caption, useStyles } from '@archipro-design/aria';
import { ErrorWarningFill } from '@archipro-design/icons';
import * as S from './WarningBanner.style';

interface WarningBannerProps {
    alerts?: string[];
}

const WarningBanner = ({ alerts }: WarningBannerProps) => {
    const { css } = useStyles();

    if (!alerts || alerts.length === 0) return null;

    return (
        <div className={`${css(S.WarningBannerContainer)} productBanner`}>
            <div className={css(S.WarningBannerContent)}>
                {alerts.map((alert) => (
                    <div key={alert} className={css(S.WarningBannerItem)}>
                        <ErrorWarningFill
                            className={css(S.WarningBannerIcon)}
                        />
                        <Caption
                            variant="03"
                            variables={{
                                lineHeight: '150%',
                                captionColor: 'white',
                            }}
                        >
                            {alert}
                        </Caption>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default WarningBanner;
