import { useMatches } from '@remix-run/react';
type HeaderTheme = 'default' | 'transparent' | 'none';

interface LoaderData {
    pageTheme?: HeaderTheme;
}

// Type guard to ensure the loader data has the correct shape
const isLoaderData = (data: unknown): data is LoaderData => {
    return (
        typeof data === 'object' &&
        data !== null &&
        'pageTheme' in data &&
        typeof (data as LoaderData).pageTheme === 'string'
    );
};

export const usePageTheme = (): HeaderTheme => {
    const matches = useMatches();

    // Iterate over matches in reverse to find the first match with pageTheme
    for (let i = matches.length - 1; i >= 0; i--) {
        const match = matches[i];

        // Ensure match exists and has the required pageTheme data
        if (match && isLoaderData(match.data) && match.data.pageTheme) {
            return match.data.pageTheme;
        }
    }

    return 'default';
};
