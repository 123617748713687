import { useTracker } from '@archipro-website/tracker';
import { useCallback } from 'react';

export const useFooterTracking = () => {
    const tracker = useTracker();

    const onNavClick = useCallback(
        (link: string) => {
            tracker.log('FooterNavClick', {
                url: new URL(window.location.href),
                data: {
                    ExtraData: link,
                },
            });
        },
        [tracker]
    );

    const onEmailSubscribe = useCallback(
        (email: string) => {
            tracker.log('FooterNavSubscribe', {
                url: new URL(window.location.href),
                data: {
                    ExtraData: email,
                },
            });
        },
        [tracker]
    );

    const onBottomLinkSectionCardClick = useCallback(
        (cardIndex: number) => {
            if (cardIndex === 0) {
                tracker.log('StartProjectClick', {
                    url: new URL(window.location.href),
                    data: {
                        ExtraData: 'BottomLinkSection',
                    },
                });
            }

            if (cardIndex === 1) {
                tracker.log('BecomeProClick', {
                    url: new URL(window.location.href),
                    data: {
                        ExtraData: 'BottomLinkSection',
                    },
                });
            }
        },
        [tracker]
    );

    return {
        onNavClick,
        onEmailSubscribe,
        onBottomLinkSectionCardClick,
    };
};
