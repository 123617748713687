import React, { useRef, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from '@remix-run/react';
import { generateURL } from '@modules-rocco/url/api/generate-url';
import { SEARCH_CATEGORIES } from '@modules-rocco/search/config/search-service.config';
import { useSearchInput } from '@rocco/ui/search/hooks/use-search-input';
import { SearchBar } from '@rocco/ui/search/components/SearchBar';

import { useSearchQuery } from '@ui-rocco/hooks/use-search-query';
import { useRecentlySearched } from '@modules-rocco/search/hook/use-recently-searched';
import type {
    SearchSuggestion,
    SearchSuggestionGroup,
} from '@rocco/ui/search/view-models/search-suggestion';
import { SearchSuggestionList } from '@rocco/ui/search/components/SearchSuggestionList';

import { isCategorySuggestion } from '@rocco/ui/search/utils/type-guards';
import { useAtom } from 'jotai';
import { Sheet, SheetContent } from '@archipro/rocco/components/sheet';

import { uiStateAtomSearchModalOpened } from '../states/ui-state-search-modal-opened';
import { ReactComponent as CloseIcon } from '@rocco/icons/svg/close.svg';
import {
    formatNumberWithCommas,
    roundToNearest,
} from '@rocco/utils/format-number';
import { useAppData } from '~/modules-rocco/root';

type MobileSearchModalView = 'recentlySearched' | 'suggestions';

function getSearchPath(pathname: string): string {
    const pathToCategoryMap: Record<string, string> = {
        '/projects': '/search/category_projects',
        '/professionals': '/search/category_professionals',
        '/products': '/search/category_products',
        '/articles': '/search/category_articles',
    };

    return pathToCategoryMap[pathname] || '/search';
}

export function MobileSearchModal() {
    const [view, setView] = useState<MobileSearchModalView>('recentlySearched');
    const [isModalOpen, setIsModalOpen] = useAtom(uiStateAtomSearchModalOpened);
    const location = useLocation();

    const { statsNumbers } = useAppData();

    const { addRecentSearch } = useRecentlySearched();
    const [, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    // web-platform specific search functions
    const query = useSearchQuery('');

    const finalPath = getSearchPath(location.pathname);

    // using rocco search input
    const searchInputResult = useSearchInput({
        query,
        doSearch: (finalQuery, searchIndex) => {
            const category = SEARCH_CATEGORIES.find(
                (c) => c.index === searchIndex
            )?.category;

            if (finalQuery) {
                setSearchParams({ search: finalQuery });
                addRecentSearch(finalQuery);
                navigate(
                    generateURL(finalPath, {
                        search: finalQuery,
                        ...(category ? { category } : {}),
                    })
                );
            } else {
                setSearchParams({});
                navigate(finalPath);
            }
        },
    });

    const {
        searchQuery: inputSearchQuery,
        handleInputChange,
        handleKeyPress,
        handleSearch,
        suggestionGroups,
        clearSearchQuery,
    } = searchInputResult;

    useEffect(() => {
        const hasSuggestions = suggestionGroups.some(
            (group) => group.options.length > 0
        );

        if (inputSearchQuery && hasSuggestions) {
            setView('suggestions');
        } else {
            setView('recentlySearched');
        }
    }, [inputSearchQuery, suggestionGroups]);

    useEffect(() => {
        // close the modal on url change
        setIsModalOpen(false);
        clearSearchQuery();
    }, [location, setIsModalOpen, clearSearchQuery]);

    const placeholderTexts = [
        `Search ${formatNumberWithCommas(roundToNearest(statsNumbers.ProductCount))}+ products`,
        `Search ${formatNumberWithCommas(roundToNearest(statsNumbers.ProfessionalCount))}+ professionals`,
        `Search ${formatNumberWithCommas(roundToNearest(statsNumbers.ProjectCount))}+ projects`,
    ];

    const searchSectionInputRef = useRef<HTMLInputElement>(null);

    return (
        <Sheet open={isModalOpen} onOpenChange={setIsModalOpen}>
            <SheetContent
                side="left"
                radius="none"
                hideDefaultCloseButton
                noOverlay
                zLevel="top"
            >
                <div className="w-dvw overflow-y-auto h-dvh py-6 px-6 bg-white">
                    <div className="flex flex-col gap-y-9.5">
                        <CloseIcon
                            role="button"
                            onClick={() => setIsModalOpen(false)}
                            aria-label="Close Search Modal"
                        />

                        <div className="flex flex-col gap-y-8">
                            <SearchBar
                                key="searchpage-searchBar"
                                ref={searchSectionInputRef}
                                placeholderTexts={placeholderTexts}
                                value={inputSearchQuery}
                                onChange={handleInputChange}
                                onKeyDown={handleKeyPress}
                                onSearchSubmit={(value) =>
                                    value &&
                                    typeof value === 'string' &&
                                    handleSearch(value)
                                }
                                suggestionGroups={suggestionGroups}
                                onSearchSuggestionClick={(suggestion) => {
                                    if (isCategorySuggestion(suggestion)) {
                                        navigate(suggestion.link);
                                    } else {
                                        handleSearch(suggestion);
                                    }
                                }}
                                clearSearchQuery={() => {
                                    searchSectionInputRef.current?.focus();
                                    clearSearchQuery();
                                }}
                                displayMode={'mobile'}
                                variant={'searchPageMobile'}
                                hideSubmitButton={true}
                                disableDropdown={true}
                            />

                            {view === 'recentlySearched' && (
                                <MobileSearchPageRecentlySearched />
                            )}
                            {view === 'suggestions' &&
                                suggestionGroups[0] &&
                                suggestionGroups
                                    .filter((group) => group.options.length > 0)
                                    .map((group) => (
                                        <MobileSearchPageSuggestedGroups
                                            key={group.type}
                                            query={inputSearchQuery}
                                            group={group}
                                        />
                                    ))}
                        </div>
                    </div>
                </div>
            </SheetContent>
        </Sheet>
    );
}

// ============================================================================
// Local Page Components (Sections)
// ============================================================================

const MobileSearchPageRecentlySearched = () => {
    const { recentlySearched, clearHistory } = useRecentlySearched();

    const [, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();
    const finalPath = getSearchPath(location.pathname);

    const handleSearch = (search: string) => {
        setSearchParams({ search });
        navigate(
            generateURL(finalPath, {
                search,
            })
        );
    };

    if (recentlySearched.length === 0) return null;

    return (
        <MobileSearchList
            onItemClick={(value) => {
                handleSearch(value);
            }}
            title="Recently Searched"
            list={recentlySearched}
            extraItem={
                <button className="font-medium" onClick={clearHistory}>
                    Clear History
                </button>
            }
        />
    );
};

const MobileSearchPageSuggestedGroups = ({
    query,
    group,
}: {
    query: string;
    group: SearchSuggestionGroup;
}) => {
    const navigate = useNavigate();
    const location = useLocation();

    const finalPath = getSearchPath(location.pathname);

    const onSearchSuggestionClick = (suggestion: SearchSuggestion | string) => {
        if (isCategorySuggestion(suggestion)) {
            navigate(suggestion.link);
        } else {
            navigate(
                generateURL(finalPath, {
                    search: suggestion,
                })
            );
        }
    };

    return (
        <SearchSuggestionList
            group={group}
            query={query}
            onOptionClick={(suggestion) => onSearchSuggestionClick(suggestion)}
        />
    );
};

const MobileSearchList = ({
    onItemClick,
    title,
    list,
    extraItem,
}: {
    onItemClick: (value: string) => void;
    title: string;
    list: string[];
    extraItem?: React.ReactNode;
}) => {
    return (
        <div className="">
            <h3 className="text-base font-medium">{title}</h3>
            <ul className="my-4.5 space-y-2.5">
                {list.map((item, idx) => (
                    <li key={idx} className="w-auto text-left">
                        <button
                            className="line-clamp-1 min-h-4.5 max-w-full truncate leading-[1.1] text-[#9b9b9b]
                            overflow-hidden text-left whitespace-nowrap block"
                            onClick={() => onItemClick(item)}
                        >
                            {item}
                        </button>
                    </li>
                ))}
            </ul>
            {extraItem}
        </div>
    );
};
