import { useCallback } from 'react';
import { useFetcher } from '@remix-run/react';
import {
    ACTION_GET_DESIGN_BOARD_LIST,
    type loader as GetDesignBoardListRemixLoader,
} from '~/routes/actions.get-design-board-list';
import {
    type action as CreateDesignBoardRemixAction,
    ACTION_CREATE_DESIGN_BOARD,
} from '~/routes/actions.create-design-board';
import {
    type action as AddItemsToDesignBoardRemixAction,
    ACTION_ADD_ITEMS_TO_DESIGN_BOARD,
} from '~/routes/actions.add-items-to-design-board';
import type { GetDesignBoardListItemResponse } from '@modules-rocco/design-board/api/get-design-board-list.server';
import type { DesignBoardsTrackingConfig } from '@rocco/ui/design-board/components/DesignBoardsActions';
import { useTracker } from '@archipro-website/tracker';
import { useUser } from '~/modules/user';

/**
 * Main hook for managing design boards functionality. Combines UI API actions and states.
 * @returns {Object} An object containing:
 * - isLoading: Boolean indicating if any operation is in progress
 * - boards: Array of design board data
 * - createdBoardData: Data from the most recent board creation
 * - addedItemsData: Data from the most recent items addition
 * - handleFetchBoards: Function to fetch all boards
 * - handleCreateBoard: Function to create a new board
 * - handleAddItemsToBoard: Function to add items to a board
 * - trackingConfig: Tracking config for design boards actions
 */
export const useDesignBoards = () => {
    const [boards, isFetchingBoards, handleFetchBoards] =
        useGetDesignBoardList();
    const [createdBoardData, isCreatingBoard, handleCreateBoard] =
        useCreateDesignBoard();
    const [addedItemsData, isAddingItems, handleAddItemsToBoard] =
        useAddItemsToDesignBoard();
    const trackingConfig = useDesignBoardsTracking();

    return {
        isLoading: isFetchingBoards || isCreatingBoard || isAddingItems,
        boards,
        createdBoardData,
        addedItemsData,
        handleFetchBoards,
        handleCreateBoard,
        handleAddItemsToBoard,
        trackingConfig,
    };
};

/**
 * Hook for fetching the list of design boards.
 * @returns A tuple containing:
 * - Array of design board data or undefined if not yet loaded
 * - Boolean indicating if fetch is in progress
 * - Function to trigger boards fetch
 */
const useGetDesignBoardList = (): [
    GetDesignBoardListItemResponse[] | undefined,
    boolean,
    () => void,
] => {
    const fetcher = useFetcher<typeof GetDesignBoardListRemixLoader>();

    const handleFetchBoards = useCallback(() => {
        fetcher.load(ACTION_GET_DESIGN_BOARD_LIST);
    }, [fetcher]);

    return [fetcher.data, fetcher.state !== 'idle', handleFetchBoards];
};

/**
 * Hook for creating a new design board.
 * @returns A tuple containing:
 * - Data from the most recent board creation
 * - Boolean indicating if creation is in progress
 * - Function to create a new board with specified title, optional description and privacy setting
 */
const useCreateDesignBoard = () => {
    const fetcher = useFetcher<typeof CreateDesignBoardRemixAction>();

    const handleCreateBoard = useCallback(
        (title: string, description?: string, isPrivate?: boolean) => {
            fetcher.submit(
                {
                    title,
                    description: description || '',
                    isPrivate: isPrivate?.toString() || 'true',
                },
                {
                    method: 'post',
                    action: ACTION_CREATE_DESIGN_BOARD,
                }
            );
        },
        [fetcher]
    );

    return [fetcher.data, fetcher.state !== 'idle', handleCreateBoard];
};

/**
 * Hook for adding items to an existing design board.
 * @returns A tuple containing:
 * - Data from the most recent items addition
 * - Boolean indicating if addition is in progress
 * - Function to add items to a board, requiring board ID, type of items, and array of item IDs
 */
const useAddItemsToDesignBoard = () => {
    const fetcher = useFetcher<typeof AddItemsToDesignBoardRemixAction>();

    const handleAddItemsToDesignBoard = useCallback(
        (boardId: number, itemsType: string, items: string[]) => {
            // Ensure items is always sent as array by using FormData
            const formData = new FormData();
            formData.append('boardId', boardId.toString());
            formData.append('itemsType', itemsType);

            items.forEach((item) => {
                formData.append('itemIds[]', item);
            });

            fetcher.submit(formData, {
                method: 'post',
                action: ACTION_ADD_ITEMS_TO_DESIGN_BOARD,
            });
        },
        [fetcher]
    );

    return [
        fetcher.data,
        fetcher.state !== 'idle',
        handleAddItemsToDesignBoard,
    ];
};

/**
 * Hook that provides tracking configuration for design board interactions.
 * Returns a config object with callbacks for tracking
 * @returns {DesignBoardsTrackingConfig} Tracking configuration object
 */
const useDesignBoardsTracking = (): DesignBoardsTrackingConfig => {
    const tracker = useTracker();
    const user = useUser();
    const guestID = user.__typename === 'Me' ? user.TrackedGuest?.ID : 0;

    return {
        onAfterBoardSelect: (board) => {
            if (board.isNew) {
                tracker.log('DesignBoardCreateSubmit', {
                    url: new URL(window.location.href),
                });
            }
        },
        onBeforeFollow: (itemId) => {
            // BC-2135: Must be the library item ID, not the image ID.
            tracker.log('PinMenuAdd', {
                url: new URL(window.location.href),
                data: {
                    LibraryItemID: itemId,
                },
            });
        },
        onAfterFollow: (itemId) => {
            // BC-2135: Must be the library item ID, not the image ID.
            tracker.log('SaveSubmit', {
                url: new URL(window.location.href),
                data: {
                    LibraryItemID: itemId,
                },
                ga4Data: {
                    LibraryItemID: itemId,
                    guest_id: guestID,
                },
            });
        },
    };
};
