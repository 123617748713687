import { ReactComponent as WarningIcon } from '../../../icons/svg/warning.svg';

interface WarningBannerProps {
    alerts: string[];
}

export const WarningBanner = ({ alerts }: WarningBannerProps) => {
    if (!alerts?.length) return null;

    return (
        <div className="w-full bg-destructive/80 px-4 py-2 space-y-2 text-white md:px-12 md:py-4">
            {alerts.map(alert => (
                <div
                    key={alert}
                    className="flex items-center justify-center gap-x-3"
                >
                    <WarningIcon className="size-4 flex-shrink-0 md:size-5" />
                    <span className="text-sm font-medium leading-6 md:text-base">
                        {alert}
                    </span>
                </div>
            ))}
        </div>
    );
};
