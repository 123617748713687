import { AddProfessionalModal } from '@modules/admin/component/add-professional-modal/AddProfessionalModal';
import LoginSignUpModal from '~/modules/user/component/login-signup-modal/LoginSignUpModal';
import { ClientOnly } from 'remix-utils';
import { useAtom, useAtomValue } from 'jotai';
import {
    uiStateAtomLoginModalAuthSource,
    uiStateAtomLoginModalOpened,
} from '~/modules-rocco/root/states/ui-state-login-modal-opened';
import { uiStateAtomAddProfessionalModalOpened } from '~/modules-rocco/root/states/ui-state-add-professional-modal-opened';

const SiteWideModals = () => {
    const [loginModalOpened, setLoginModalOpened] = useAtom(
        uiStateAtomLoginModalOpened
    );
    const authSource = useAtomValue(uiStateAtomLoginModalAuthSource);
    const [addProfessionalModalOpened, setAddProfessionalModalOpened] = useAtom(
        uiStateAtomAddProfessionalModalOpened
    );

    return (
        <ClientOnly fallback={''}>
            {() => (
                <>
                    {addProfessionalModalOpened && (
                        <AddProfessionalModal
                            onClose={() => setAddProfessionalModalOpened(false)}
                            isOpen={addProfessionalModalOpened}
                        />
                    )}
                    {loginModalOpened && (
                        <LoginSignUpModal
                            login={{
                                open: true,
                                onCancel: () => setLoginModalOpened(false),
                                onConfirm: () => setLoginModalOpened(false),
                                onAuthSuccess: () => setLoginModalOpened(false),
                                flow: null,
                                authSource: authSource || 'unknown',
                            }}
                            forgotPassword={{
                                open: true,
                                onCancel: () => setLoginModalOpened(false),
                            }}
                        />
                    )}
                </>
            )}
        </ClientOnly>
    );
};

export default SiteWideModals;
