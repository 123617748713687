import { useEffect, useState } from 'react';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from '../../../utils/cn';
import { ReactComponent as NavIcon } from '../../../icons/svg/nav.svg';
import { Button } from '../../../components/button';
import { ReactComponent as LoginIcon } from '../../../icons/svg/user.svg';
import { ReactComponent as SearchIcon } from '../../../icons/svg/search.svg';
import {
    _headerVariants,
    HeaderCartInfo,
    type HeaderNavLinks,
    type HeaderUserData,
} from './Header';
import {
    Sheet,
    SheetContent,
    SheetDescription,
    SheetTitle,
    SheetTrigger,
} from '../../../components/sheet';
import { MobileNavMenu } from './MobileNavMenu';
import { MobileUserMenu } from './user-menu/MobileUserMenu';
import { LogoAnimated } from '../../../components/logo-animated/LogoAnimated';
import { RoccoLink } from '../../../components/link/RoccoLink';
import { WarningBanner } from '../../../ui/warning-banner/components/WarningBanner';
import { UserCart } from './UserCart';

const _headerIconVariants = cva('z-fixed', {
    variants: {
        fill: {
            dark: 'fill-gray-900 text-gray-900',
            light: 'fill-white text-white',
            none: 'fill-none',
        },
        outline: {
            default: '',
            light: 'fill-none stroke-white',
            dark: 'fill-none stroke-gray-900',
        },
    },
    defaultVariants: {
        fill: 'dark',
        outline: 'default',
    },
});

interface MobileHeaderProps extends VariantProps<typeof _headerVariants> {
    primaryLinks: HeaderNavLinks;
    secondaryLinks: HeaderNavLinks;
    profileLinks: HeaderNavLinks;
    userData: HeaderUserData;
    onLogout: () => void;
    onLoginClick: () => void;
    currentUrl: string;
    onUserCartClick: () => void;
    warningMessages?: string[];
    isLegacyBrokenRemMode?: boolean;
    onMobileSearchClick?: () => void;
    cartInfo: HeaderCartInfo;
}

export const MobileHeader = (props: MobileHeaderProps) => {
    const {
        primaryLinks,
        secondaryLinks,
        profileLinks,
        color,
        userData,
        onLogout,
        currentUrl,
        mode,
        onUserCartClick,
        warningMessages,
        isLegacyBrokenRemMode,
        onMobileSearchClick,
        cartInfo,
        onLoginClick,
    } = props;

    const menuColor = color == 'transparent' ? 'light' : 'dark';
    const fillClass = _headerIconVariants({ fill: menuColor });
    const outlineClass = _headerIconVariants({ outline: menuColor });
    const basicTheme = cn(
        'z-fixed md:hidden',
        _headerVariants({ color, mode }),
    );

    const [isNavOpen, setNavOpen] = useState(false);

    const { cartCount } = cartInfo;

    useEffect(() => {
        setNavOpen(false);
    }, [currentUrl]);

    return (
        <div className={basicTheme}>
            {/* Keep height in sync with drawer max height. */}
            {/* packages/rocco/src/components/drawer/drawer-content.tsx */}
            <div className="relative flex h-19 items-center justify-between px-4 py-3">
                <Sheet open={isNavOpen} onOpenChange={setNavOpen}>
                    <SheetTrigger asChild>
                        <button
                            className="flex items-center justify-center border-none outline-none focus-visible:outline-none"
                            aria-label="Toggle Navigation Menu"
                        >
                            <NavIcon className={cn('', outlineClass)} />
                        </button>
                    </SheetTrigger>
                    <SheetContent
                        side="left"
                        radius="none"
                        hideDefaultCloseButton
                        noOverlay
                        zLevel="top"
                    >
                        <SheetTitle>
                            <p className="sr-only">Navigation Menu</p>
                        </SheetTitle>
                        <SheetDescription hidden>
                            <span className="sr-only">
                                Navigation Menu on the Header
                            </span>
                        </SheetDescription>

                        <MobileNavMenu
                            primaryLinks={primaryLinks}
                            secondaryLinks={secondaryLinks}
                            isAuthorized={userData.isAuthorized}
                            close={() => setNavOpen(false)}
                            currentUrl={currentUrl}
                            logout={onLogout}
                        />
                    </SheetContent>
                </Sheet>

                <RoccoLink
                    to="/"
                    className={cn(
                        'absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2',
                        fillClass,
                    )}
                    aria-label="Home"
                >
                    <LogoAnimated
                        size={1.6}
                        mode="disabled"
                        color={menuColor}
                        isLegacyBrokenRemMode={isLegacyBrokenRemMode}
                    />
                </RoccoLink>

                <div className="flex items-center gap-x-3">
                    <div className="flex gap-x-3">
                        {onMobileSearchClick ? (
                            <Button
                                size="square24"
                                shape="square"
                                color="transparent"
                                overflow="visible"
                                asChild
                                onClick={onMobileSearchClick}
                            >
                                <SearchIcon
                                    className={cn(outlineClass, 'size-6')}
                                />
                            </Button>
                        ) : (
                            <Button
                                size="square24"
                                shape="square"
                                color="transparent"
                                overflow="visible"
                                asChild
                            >
                                <RoccoLink to="/search">
                                    <SearchIcon
                                        className={cn(outlineClass, 'size-6')}
                                    />
                                </RoccoLink>
                            </Button>
                        )}
                    </div>

                    {cartCount > 0 && (
                        <div className="relative top-1.5">
                            <UserCart
                                onUserCartClick={onUserCartClick}
                                fill={menuColor}
                                cartInfo={cartInfo}
                            />
                        </div>
                    )}
                    {userData.isAuthorized ? (
                        <div className="flex">
                            <MobileUserMenu
                                userData={userData}
                                profileLinks={profileLinks}
                                onLogout={onLogout}
                            />
                        </div>
                    ) : (
                        <div className="flex gap-x-3">
                            <Button
                                size="square24"
                                shape="square"
                                color="transparent"
                                overflow="visible"
                                asChild
                            >
                                <button
                                    aria-label="Log in"
                                    onClick={onLoginClick}
                                >
                                    <LoginIcon
                                        className={cn(outlineClass, 'size-6')}
                                    />
                                </button>
                            </Button>
                        </div>
                    )}
                </div>
            </div>
            {!!warningMessages?.length && (
                <WarningBanner alerts={warningMessages} />
            )}
        </div>
    );
};
