import React from 'react';
import { Badge } from '../../../../components/badge';
import { RoccoLink } from '../../../../components/link/RoccoLink';

interface UserMenuElementProps {
    title: string;
    icon?: React.ReactNode;
    to?: string;
    /** The link is not a Remix route. */
    external?: boolean;
    badgeCount?: number;
    onClick?: () => void;
}

/**
 * Link for the desktop user menu.
 */
export const UserMenuElement = ({
    title,
    icon,
    to,
    external = false,
    badgeCount = 0,
    onClick,
}: UserMenuElementProps) => {
    return (
        <div>
            <RoccoLink
                className="flex h-8 items-center justify-between px-2 text-gray-900 hover:text-gray-900 hover:opacity-70"
                to={to || '#'}
                prefetch="intent"
                role={onClick ? 'button' : 'link'}
                isNonRemixRoute={external}
                onClick={e => {
                    if (onClick) {
                        e.preventDefault();
                        onClick();
                    }
                }}
            >
                <span className="flex items-center gap-x-2">
                    <span className="size-4.5 flex items-center justify-start">
                        {icon}
                    </span>
                    <span className="text-sm">{title}</span>
                </span>
                {badgeCount > 0 && (
                    <Badge
                        size="centeredCompact"
                        text={badgeCount > 9 ? 'small' : 'default'}
                    >
                        {badgeCount > 9 ? '9+' : badgeCount}
                    </Badge>
                )}
            </RoccoLink>
        </div>
    );
};
