import type { ActionFunctionArgs } from '@remix-run/node';
import { createNewDesignBoard } from '@modules-rocco/design-board/api/create-new-design-board.server';
import { ServerErrorResponse } from '@modules/root/graphql/responses';

export const ACTION_CREATE_DESIGN_BOARD = '/actions/create-design-board';

interface CreateDesignBoardFormData {
    title: string;
    description: string;
    isPrivate: boolean;
}

export const action = async ({ request, context }: ActionFunctionArgs) => {
    const formData = await request.formData();
    const formObj = Object.fromEntries(
        formData
    ) as unknown as CreateDesignBoardFormData;

    if (!formObj.title) {
        return new ServerErrorResponse('Title is required');
    }

    return await createNewDesignBoard(formObj, context);
};
