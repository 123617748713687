import type { StyleRule } from '@archipro-design/aria';
import { pxToRem } from '@archipro-design/aria';

export const PageBase: StyleRule = () => ({
    minHeight: '100vh',

    '@supports(height: 100dvh)': {
        minHeight: '100dvh',
    },
});

export const PageContent: StyleRule = ({ theme, currentPageTheme }) => ({
    flex: '1',
    ...(currentPageTheme === 'default' && {
        paddingTop: pxToRem(74),

        [theme.screen.tablet]: {
            paddingTop: 0,
        },
    }),
});
