import { cn } from '../../../utils/cn';
import { cva } from 'class-variance-authority';
import type { VariantProps } from 'class-variance-authority';
import type { HeaderNavLinks } from './Header';
import { RoccoLink } from '../../../components/link/RoccoLink';

const _categoriesMenuItemVariants = cva(
    'text-base decoration-2 underline-offset-8 data-[active]:underline',
    {
        variants: {
            color: {
                light: 'text-white hover:text-white',
                dark: 'text-gray-900 hover:text-gray-900',
            },
        },
        defaultVariants: {
            color: 'light',
        },
    },
);

interface CategoriesMenuProps
    extends VariantProps<typeof _categoriesMenuItemVariants> {
    links: HeaderNavLinks;
    currentUrl: string;
}

/**
 * The navigation links for the desktop header.
 */
export const CategoriesMenu = (props: CategoriesMenuProps) => {
    const { color, links, currentUrl } = props;

    return (
        <nav>
            <ul className="flex space-x-20">
                {links.links.map(({ to, title, prefix, isNonRemixRoute }) => {
                    const isCurrent = currentUrl.includes(to);

                    return (
                        <li key={title}>
                            <RoccoLink
                                to={to}
                                prefetch="intent"
                                className={cn(
                                    'group block whitespace-nowrap',
                                    _categoriesMenuItemVariants({
                                        color,
                                    }),
                                )}
                                isNonRemixRoute={isNonRemixRoute}
                            >
                                {prefix}
                                <br />
                                <span
                                    className={cn(
                                        'font-medium group-hover:underline',
                                        isCurrent && 'underline',
                                    )}
                                >
                                    {title}
                                </span>
                            </RoccoLink>
                        </li>
                    );
                })}
            </ul>
        </nav>
    );
};
