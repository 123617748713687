import type { ActionFunctionArgs } from '@remix-run/node';
import { ServerErrorResponse } from '@modules/root/graphql/responses';
import {
    addItemsToDesignBoard,
    type ItemType,
} from '~/modules-rocco/design-board/api/add-items-to-design-board.server';

export const ACTION_ADD_ITEMS_TO_DESIGN_BOARD =
    '/actions/add-items-to-design-board';

interface AddItemsToDesignBoardFormData {
    boardId: number;
    itemsType: ItemType;
    itemIds: number[];
}

export const action = async ({ request, context }: ActionFunctionArgs) => {
    const formData = await request.formData();
    const formObj: AddItemsToDesignBoardFormData = {
        boardId: Number(formData.get('boardId')),
        itemsType: formData.get('itemsType') as ItemType,
        itemIds: formData.getAll('itemIds[]').map(Number),
    };

    if (formObj.boardId < 0) {
        // '0' is used for guest design boards
        return new ServerErrorResponse('Board ID is required');
    }

    if (!formObj.itemsType) {
        return new ServerErrorResponse('Items type is required');
    }

    if (!formObj.itemIds) {
        return new ServerErrorResponse('Item IDs are required');
    }

    return await addItemsToDesignBoard(formObj, context);
};
