import { z } from 'zod';
import { PLACE_INPUT } from '@modules/validation/util/form';

export const enquiryFormSessionSchema = z.object({
    name: z.string().trim().min(1),
    email: z.string().trim().email(),
    phoneNumber: z.string().trim().min(1),
    postcode: z.object({ header: z.string(), value: PLACE_INPUT }),
    branchId: z.number().int().positive().optional(),
    message: z.string().trim().min(1),
    siteTreeId: z.number().int().positive(),
    source: z.enum(['InPage', 'EnquiryModal']),
    professional: z.object({
        ID: z.number().int().positive(),
        Title: z.string(),
    }),
    directoryType: z
        .enum(['professional', 'product', 'project', 'article'])
        .optional(),
    relatedItem: z
        .object({
            id: z.number().int().positive(),
            type: z.enum(['professional', 'product', 'project', 'article']),
            title: z.string(),
            category: z.string(),
        })
        .optional(),
});
