import type {
    GetProfessionalPagesRootDataQuery,
    GetProjectDetailQuery,
    Article,
    Product,
    SupportedCountry,
} from 'generated/graphql';

const getThirdPathSegment = (url?: string): string | null => {
    if (!url) return null;
    const pathSegments = url.split('/').filter(Boolean);
    return pathSegments.length >= 3 ? pathSegments[2] ?? null : null;
};

export type AssociatedPagesType =
    | Product['AssociatedPages']
    | Article['AssociatedPages']
    | Extract<
          GetProjectDetailQuery['Directory']['Project'],
          { __typename: 'Project' }
      >['AssociatedPages']
    | Extract<
          GetProfessionalPagesRootDataQuery['Directory']['professionalInitial'],
          { __typename: 'Professional' }
      >['AssociatedPages'];

export interface CloneCountryState {
    urlSegment: string | null;
    currentCountry: string | null;
    pageType: string | null;
    associatedPages: AssociatedPagesType | null;
}

export interface HeaderCountryElement {
    code: string;
    name: string;
    url: string;
}

export const getCountrySwitchUrl = (
    country: HeaderCountryElement,
    countryData: CloneCountryState,
    currentUrl: string
): string => {
    // If no page type or no associated pages, return the default country URL
    if (
        !countryData?.pageType ||
        !countryData?.associatedPages?.edges?.length
    ) {
        return country.url;
    }

    const edges = countryData.associatedPages
        .edges as AssociatedPagesType['edges'][number][];

    const associatedEdgePage = edges?.find(
        (edge: AssociatedPagesType['edges'][number]): edge is typeof edge => {
            return (
                edge?.node?.Zone?.Country?.Title?.toLowerCase() ===
                country.name.toLowerCase()
            );
        }
    );
    // If we're on the current country's URL and have associated pages
    if (
        country.code === countryData.currentCountry &&
        associatedEdgePage?.node?.ZonePage?.Link
    ) {
        return currentUrl;
    }
    // If we found an associated page for the target country
    const baseUrl = associatedEdgePage?.node?.ZonePage?.Link;
    if (baseUrl) {
        // Parse the baseUrl and replace the domain
        const newDomain = handleDomainOrigin(
            baseUrl,
            country.code as SupportedCountry
        );
        // Get the third path segment if it exists
        const thirdPathSegment = getThirdPathSegment(currentUrl);
        const fullLink = thirdPathSegment
            ? `${newDomain}/${thirdPathSegment}`
            : newDomain;

        return fullLink;
    }
    const switchCountryUrl = handleDomainOrigin(
        `${country.url}${currentUrl}`,
        country.code as SupportedCountry
    );
    // Fallback to default country URL
    return switchCountryUrl;
};

const handleDomainOrigin = (
    url: string,
    targetCountryCode?: SupportedCountry
): string => {
    // Extract domain from URL
    const match = url.match(/\.([^.]+\.[^.]+)$/);
    if (!match) return url; // Return original if no match

    // If no target country specified, just return the current domain origin
    if (!targetCountryCode) {
        return match[1] === 'com.au' ? 'com.au' : 'co.nz';
    }

    // Handle URLs with or without protocol
    const fullUrl = url.startsWith('http') ? url : `https://${url}`;

    try {
        const urlObj = new URL(fullUrl);
        const targetOrigin = targetCountryCode === 'AU' ? 'com.au' : 'co.nz';
        // Replace the domain origin
        urlObj.hostname = urlObj.hostname.replace(
            /(co\.nz|com\.au)$/,
            targetOrigin
        );
        return urlObj.toString();
    } catch (e) {
        return url; // Return original if invalid URL
    }
};
