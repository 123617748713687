// eslint-disable-next-line no-restricted-syntax
export enum PagesEnum {
    PROJECTS = '/projects',
    PRODUCTS = '/products',
    PROFESSIONALS = '/professionals',
    ARCHIZEEN = '/articles',
    DIGITAL_MAGAZINES = '/digital-magazines',
    BECOME_A_PRO = '/become-a-pro',
    FOR_SUPPLIERS_AND_BRANDS = '/sell-on-archipro',
    SELL_ON_ARCHIPRO = '/ecommerce-launch',
    ABOUT = '/about-us',
    CAREERS = 'https://apply.workable.com/archipro-3',
    CONTACT = '/contact-us',
    FAQS = `https://resources.{AP_DOMAIN_PLACE}/ecommerce-faqs`,
    HOME_DESIGN_EVENING = '/home-design-event',
    INSTAGRAM = 'https://www.instagram.com/archipro_/',
    FACEBOOK = 'https://www.facebook.com/archipro/',
    LINKEDIN = 'https://www.linkedin.com/company/archipro',
    YOUTUBE = 'https://www.youtube.com/channel/UCw3LUhWr0j-z2rpqGfQK8Tg',
    HOW_IT_WORKS = '/how-it-works',
    // Member-related routes
    MY_DESIGN_BOARDS = '/member/design-boards',
    MY_ORDERS = '/member/orders',
    MY_NOTIFICATIONS = '/member/notifications',
    MY_SETTINGS = '/member/settings/edit-profile',
    MY_INBOX = '/member/inbox',
}

export const PROFILE_NAV = {
    title: 'PROFILE_NAV',
    links: [
        { title: 'My Inbox', to: PagesEnum.MY_INBOX },
        { title: 'My Design Boards', to: PagesEnum.MY_DESIGN_BOARDS },
        { title: 'My Orders', to: PagesEnum.MY_ORDERS },
        { title: 'My Notifications', to: PagesEnum.MY_NOTIFICATIONS },
        { title: 'My Settings', to: PagesEnum.MY_SETTINGS },
    ],
};
