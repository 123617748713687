import type { ChooseDesignBoardModalProps } from '@archipro-design/aria';
import { useLocalStorageState } from 'ahooks';
import SaveToDesignBoard from '~/modules/design-board/component/SaveToDesignBoard';
import {
    USER_BOARD_LIST_STORAGE_KEY,
    USER_ID_STORAGE_KEY,
} from '~/modules/design-board/config/design-board-config';
import { useUser } from '@modules/user';
import { ClientOnly } from 'remix-utils';
import { useEffect } from 'react';
import { useDesignBoardAddPinFromLocal } from '~/modules/design-board/hook/use-design-board-add-pin-from-local';

/**
 * If there is no saved design-board-data in the local storage
 * then initialize the saved-design-board & saved-pin data
 * If the saved-design-board already exists then do nothing
 */
const DesignBoardInitializer = () => {
    // TODO: Remove this eventually
    const user = useUser();

    const [localBoardList, setLocalBoardList] = useLocalStorageState<
        ChooseDesignBoardModalProps['designBoards'] | undefined
    >(USER_BOARD_LIST_STORAGE_KEY);

    const [localUserId] = useLocalStorageState<number>(USER_ID_STORAGE_KEY);

    const shouldInitializeBoardData =
        !localBoardList || (localUserId && user.ID !== localUserId);

    useEffect(() => {
        if (localUserId && user.ID !== localUserId) {
            setLocalBoardList(undefined);
        }
    }, [localUserId, user.ID, setLocalBoardList]);

    useDesignBoardAddPinFromLocal();

    return shouldInitializeBoardData ? (
        <ClientOnly fallback={''}>
            {() => (
                <SaveToDesignBoard
                    isInitializeOnly={true}
                    itemId={0}
                    itemType="ProfessionalPin"
                    onSave={() => true}
                />
            )}
        </ClientOnly>
    ) : (
        <></>
    );
};

export default DesignBoardInitializer;
