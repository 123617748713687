import React, { useEffect, useState } from 'react';
import { RoccoLink } from '../../../components/link/RoccoLink';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import {
    Form as RoccoForm,
    FormControl,
    FormField,
    FormItem,
    FormMessage,
} from '../../../components/form';
import { Input } from '../../../components/input';
import { Button } from '../../../components/button';
import { ReactComponent as ArrowRight } from '../../../icons/svg/arrow/arrow-right.svg';
import { uiFooterTrackingConfig } from './Footer';
import { useLogger } from '@archipro-website/logger/client';
import { useAtomValue } from 'jotai';
import { FetcherWithComponents } from '@remix-run/react';

// Define Zod schema for email validation
const emailSchema = z.object({
    email: z
        .string()
        .min(1, { message: 'Email address is required' })
        .email({ message: 'Invalid email address' })
        .trim(),
});

type EmailFormInputs = z.infer<typeof emailSchema>;

export type SubscriptionFetcher = FetcherWithComponents<{
    message?: string;
    success?: boolean;
}>;

interface EmailSubscriptionFormProps {
    action: string;
    fetcher: SubscriptionFetcher;
}

export const EmailSubscriptionForm = (props: EmailSubscriptionFormProps) => {
    const { action, fetcher } = props;

    const { data, state } = fetcher;
    const error = data?.message || '';
    const success = !!data?.success;

    const [message, setMessage] = useState('');
    const [email, setEmail] = useState('');
    const trackingConfig = useAtomValue(uiFooterTrackingConfig);

    /**
     * Tracks successful email subscriptions by sending the submitted email address
     * to the tracking system when the subscription request succeeds.
     */
    useEffect(() => {
        if (success && email) {
            trackingConfig?.onEmailSubscribe(email);
            setEmail('');
        }
    }, [success, email, trackingConfig]);

    useEffect(() => {
        setMessage(
            state === 'submitting'
                ? 'Subscribing...'
                : state === 'idle'
                  ? error
                  : '',
        );
    }, [state, error]);

    const form = useForm<EmailFormInputs>({
        resolver: zodResolver(emailSchema),
        defaultValues: {
            email: '',
        },
    });

    const logger = useLogger();

    const onSubmit = (data: EmailFormInputs) => {
        setEmail(data.email);
        fetcher.submit(data, { method: 'post', action });
    };

    const emailSubscription = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const submit = form.handleSubmit(onSubmit);
        submit(e).catch(err => {
            logger.error(`Error while submitting email subscription.`, {
                error: typeof err === 'string' ? err : JSON.stringify(err),
            });
            setMessage('Error while submitting email subscription.');
        });
    };

    const renderForm = () => (
        <>
            <div className="mb-8 md:pr-6 text-base leading-5 text-gray-900/50 md:mb-10 md:font-medium md:text-gray-900">
                Stay informed about the newest projects, products, and
                technologies shaping the future of the architecture and design
                environment.
            </div>
            <fetcher.Form onSubmit={emailSubscription} data-hs-cf-bound="true">
                <RoccoForm {...form}>
                    <div className="relative">
                        <FormField
                            control={form.control}
                            name="email"
                            render={({ field }) => (
                                <FormItem>
                                    <FormControl>
                                        <Input
                                            placeholder="Email Address"
                                            shape="rounded"
                                            type="email"
                                            color="gray"
                                            variant="large"
                                            autoComplete="email"
                                            {...field}
                                        />
                                    </FormControl>
                                    <div className="absolute left-0 -bottom-10 h-full flex items-center">
                                        <FormMessage>{message}</FormMessage>
                                    </div>
                                </FormItem>
                            )}
                        />

                        <div className="absolute right-5 top-0 h-full flex items-center">
                            <Button
                                size="square44"
                                color="transparent"
                                type="submit"
                                disabled={state === 'submitting'}
                            >
                                <ArrowRight className="size-5" />
                            </Button>
                        </div>
                    </div>
                </RoccoForm>
            </fetcher.Form>
        </>
    );

    return (
        <div>
            <div className="mb-4 text-base font-medium text-gray-900 md:mb-4 md:font-normal md:text-gray-900/50">
                Subscribe
            </div>
            {success ? <SuccessMessage /> : renderForm()}
        </div>
    );
};

const SuccessMessage = () => (
    <>
        <div className="mb-10 text-base font-normal leading-5 text-gray-900/50 md:font-medium md:text-gray-900">
            Great! You are now subscribed to our Digital Magazines.
            <br />
            Would you like to create an account to Get Started?
        </div>

        <Button asChild>
            <RoccoLink to="/login">
                <span>Get Started Now</span>
                <ArrowRight className="ml-2 w-4.5" />
            </RoccoLink>
        </Button>
    </>
);
