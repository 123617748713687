import { CountryInfo, SupportedCountry } from '../types';

export const countriesList: Record<SupportedCountry, CountryInfo> = {
    NZ: {
        shortName: 'NZ',
        name: 'New Zealand',
    },
    AU: {
        shortName: 'AU',
        name: 'Australia',
    },
};
