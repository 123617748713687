import type { ProfileNavigationItemProps } from '@archipro-design/aria';
import {
    useStyles,
    useTheme,
    useAppDisplayModeContext,
    useDeviceTypeContext,
} from '@archipro-design/aria';
import {
    generateURL,
    getString,
    makeRenderAnchorTagFn,
    MODAL_KEYS,
    parseURL,
    TopNavigation,
} from '@archipro-website/top-navigation';
import type {
    Cart,
    Notifications,
    SearchNavResults,
    User,
    RenderAnchorTag,
    ActiveMegaMenuStateProp,
} from '@archipro-website/top-navigation';

import { ask as askWhatInput } from '@fluentui/react-northstar';

import type { UIMatch } from '@remix-run/react';
import { useRouteLoaderData } from '@remix-run/react';
import {
    Form,
    useFetcher,
    useLocation,
    useMatches,
    useNavigate,
    useSearchParams,
} from '@remix-run/react';
import { motion, useScroll as useScrollFramer } from 'framer-motion';
import { useBoolean, useDebounce, useDebounceFn, useToggle } from 'ahooks';
import { getFeaturedTile } from '../../util/get-featured-tile';
import { useEffect, useMemo, useState, useRef } from 'react';
import { useCallback } from 'react';
import { useLogout } from '@modules/user';
import type { CartSlotPopupState } from '@archipro-website/top-navigation/src/lib/slots/cartSlot';
import type { APHandle } from '@modules/root';
import { useAppData, useMatchesHandleData, useRootData } from '@modules/root';
import { useScroll } from 'ahooks';
import { useTracker } from '@archipro-website/tracker';
import { getDirectoryTypeByPathname } from '~/modules/navigation/util/get-directory-type-by-pathname';
import { secondaryNavBlockList } from '@modules/navigation/config/secondary-nav-block-list';
import { AddProfessionalModal } from '@modules/admin/component/add-professional-modal/AddProfessionalModal';
import { useCategoryTreeData } from '../../hook/use-category-tree-data';
import { useSecondaryNavData } from '../../hook/use-secondary-nav-data';
import * as S from './TopNavigation.style';
import { getAdFeaturedTile } from '@modules/navigation/util/get-ad-featured-tile';
import { useHydrated, CrossAppLink } from 'remix-utils';
import { useTouchDevice } from '@modules/root/hook/use-touch-device';
import { useLogger } from '@archipro-website/logger/client';
import { USER_PREFERENCE_KEY } from '~/modules/search/hook/use-search-filters';
import { fetchMegaMenuAdForCategory } from '~/modules/adverts/utils/sponsorship-ad-helper';
import { useConfigContext } from '../../../../../../config/src/bindings/react/ConfigContext';
import type { NotificationLoaderData } from '~/routes/remix-api.user.notifications';
import { useABTestIsOn } from '@modules/root/hook/use-growthbook';
import { useAuth } from '~/modules/root/context';
import { useSiteGate } from '~/modules/root/hook/use-site-gating';
import WarningBanner from '@modules/root/component/warning-banner/WarningBanner';
import { usePotmHeroEnabled } from '@modules/projects-category/util/potm-hero-enabled';
import type { loader as ProjectsLoader } from '~/routes/_app.projects.$';

// eslint-disable-next-line import/no-unused-modules
export interface TopNavigationData {
    user: User;
    notifications?: Notifications;
    shoppingCart?: Cart | undefined;
}

interface Props {
    data: TopNavigationData;
    cartPopupState: CartSlotPopupState;
    remixEnabled?: boolean;
    minimalTopNav?: boolean;
    ecommerceEnabled?: boolean;
    backToTopEnabled?: boolean;
    shopNavEnabled?: boolean;
    searchPreFiliteringEnabled?: boolean;
    showGuestNav?: boolean;
    warningMessages?: string[];
}

const TopNavigationRemix = ({
    data,
    cartPopupState,
    remixEnabled,
    minimalTopNav,
    ecommerceEnabled = false,
    backToTopEnabled = false,
    shopNavEnabled = false,
    searchPreFiliteringEnabled = false,
    showGuestNav,
    warningMessages,
}: Props) => {
    const appData = useAppData();
    const { canCreateProfessional } = appData;
    const navigate = useNavigate();
    const theme = useTheme();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const matches = useMatches();

    const { user, notifications, shoppingCart } = data;
    const appDisplayMode = useAppDisplayModeContext();
    const deviceType = useDeviceTypeContext();
    const tracker = useTracker();
    const hydrated = useHydrated();

    const { env } = useRootData();
    const logger = useLogger();
    const config = useConfigContext();
    const { showAuthModal, hideAuthModal } = useAuth();

    useSiteGate();

    const hasTouchSupport = useTouchDevice();
    const isUsingTouch = hasTouchSupport && askWhatInput() === 'touch';

    // Notifications
    const notificationsFetcher = useFetcher<NotificationLoaderData>();

    const renderAnchorTag = useMemo(() => {
        return makeRenderAnchorTagFn(
            'web',
            {
                linkComponent: CrossAppLink,
            },
            remixEnabled
        );
    }, [remixEnabled]);

    // logout/login
    const { logoutSideEffects } = useLogout();

    // PersonalMenu
    const personalMenuState = useBoolean(false);
    const [showPersonalMenu, expandedPersonalMenuActions] = personalMenuState;
    const flagMenuState = useBoolean(false);
    const [showFlagMenu, expandedFlagMenuActions] = flagMenuState;

    const [showAddProfessional, setShowAddProfessional] = useBoolean();

    const renderAnchorCallback: RenderAnchorTag = useCallback(
        (link: string) => {
            if (link === '/login') {
                return {
                    onClick: () => {
                        showAuthModal({ authSource: 'topNav' });
                        tracker.log('accountLogin', {
                            url: new URL(window.location.href),
                            data: {
                                step: 1,
                            },
                        });
                    },
                };
            }
            if (link.includes(MODAL_KEYS.NEW_PROFESSIONAL)) {
                return { onClick: () => setShowAddProfessional.setTrue() };
            }
            if (link === '/logout') {
                return {
                    as: 'a',
                    children: (
                        Component: React.FC<ProfileNavigationItemProps>,
                        props: ProfileNavigationItemProps
                    ) => {
                        return (
                            <Form
                                method="post"
                                action="/remix-api/user/logout"
                                reloadDocument
                                onSubmit={() => {
                                    hideAuthModal();
                                    logoutSideEffects();
                                }}
                            >
                                <button
                                    type="submit"
                                    style={{
                                        all: 'unset',
                                        width: '100%',
                                        cursor: 'pointer',
                                    }}
                                >
                                    <Component {...props} />
                                </button>
                            </Form>
                        );
                    },
                };
            }
            return renderAnchorTag(link);
        },
        [
            renderAnchorTag,
            tracker,
            setShowAddProfessional,
            logoutSideEffects,
            showAuthModal,
            hideAuthModal,
        ]
    );

    // Search stuff
    const fetcher = useFetcher<SearchNavResults | null>();
    const currentSearch = searchParams.get('search') ?? '';
    const { run: search } = useDebounceFn(
        (searchQuery) => {
            searchParams.set('search', searchQuery);
            fetcher.load(
                `/remix-api/search/search-menu?${searchParams.toString()}`
            );
        },
        {
            wait: 300,
            leading: true,
        }
    );

    const isHomePage =
        location.pathname.startsWith('/welcome') || location.pathname === '/';

    const isSearchRoute = location.pathname.startsWith('/search');
    // This side effect expands the search bar by default. Relevant to desktop searchpage only
    const isSearchBarExpandedDesktop = appDisplayMode.desktop && isSearchRoute;

    const isArticleRoute = matches.some((route) =>
        route.pathname.startsWith('/article')
    );

    const isProfessionalDetailRoute = matches.some((route) =>
        route.pathname.startsWith('/professional/')
    );

    const potmHeroEnabled = usePotmHeroEnabled();

    const projectsPageData = useRouteLoaderData<typeof ProjectsLoader>(
        'routes/_app.projects.$'
    );
    const hasHeroPotm = projectsPageData?.hasHeroPotmMedia;
    const showPotmHero = hasHeroPotm && potmHeroEnabled;

    const showTransparentTopNav =
        showPotmHero || (isProfessionalDetailRoute && !appDisplayMode.desktop);

    const desktopDefaultShowFullLogo =
        appDisplayMode.desktop && (isHomePage || isArticleRoute);

    const disableSlideAnimation = matches.some(
        (match) =>
            (match as UIMatch<unknown, APHandle>)?.handle
                ?.disableTopNavAnimation
    );

    const fadeTopNavAfterPosition = useMatchesHandleData(
        'fadeTopNavAfterPosition',
        0
    );

    const showStickyNavAfterPosition = useMatchesHandleData(
        'showStickyNavAfterPosition',
        0
    );

    // fulllogo for mobile and all routes except search and homepage
    const mobileDefaultShowFullLogo = appDisplayMode.mobile && !isSearchRoute;

    const categoryTreeData = useCategoryTreeData();

    // megamenu directory
    const [activeDirectory, setActiveDirectory] = useState<
        ActiveMegaMenuStateProp | undefined
    >({ directory: getDirectoryTypeByPathname(location.pathname) });

    useEffect(() => {
        // load up the correct directory on SPA route change
        setActiveDirectory({
            directory: getDirectoryTypeByPathname(location.pathname),
        });
    }, [location.pathname]);

    const shouldHideSecondaryNav =
        secondaryNavBlockList.some((url) => location.pathname.includes(url)) ||
        potmHeroEnabled;

    let { data: secondaryNavData } = useSecondaryNavData({
        directory:
            activeDirectory?.directory ??
            getDirectoryTypeByPathname(location.pathname),
        skip: shouldHideSecondaryNav,
    });

    if (!shopNavEnabled) {
        secondaryNavData = secondaryNavData.filter(
            (item) => item.name !== 'Shop'
        );
    }

    // TopNav postion State
    const [isNavVisible, setIsNavVisible] = useState(true);
    const scrollListenerActiveRef = useRef(false);
    const debounceIsNavVisible = useDebounce(isNavVisible, { wait: 150 });

    // Style
    const { css } = useStyles({
        showSubNavigation: secondaryNavData.length > 0,
        isDesktop: appDisplayMode.desktop,
        backToTopEnabled: backToTopEnabled,
        showTransparentTopNav: showTransparentTopNav,
    });

    // Reset visibility when the user changes routes.
    useEffect(() => {
        setIsNavVisible(true);
        scrollListenerActiveRef.current = false;
    }, [location.pathname]);

    const scroll = useScroll();
    // Watch the scroll progress to animate the nav (hide/reveal)
    const { scrollY } = useScrollFramer();

    // Fade the nav when the user scrolls past a certain point. (used on professional page on desktop)
    const shouldFade =
        scroll &&
        appDisplayMode.desktop &&
        fadeTopNavAfterPosition > 0 &&
        scroll.top > fadeTopNavAfterPosition;

    const [menuDrawerOpen, { set: setMenuDrawerOpen }] = useToggle();
    // Attach the scroll event listener to the window object.
    useEffect(() => {
        // This function will toggle the visibility of the nav based on the user's scrolling direction.
        const handleScroll = () => {
            if (!scrollListenerActiveRef.current) {
                // BC-1112 bugfix to not change the nav visibility state from scrollY useScrollFramer on first frame as it doesn't always report the correct value
                scrollListenerActiveRef.current = true;
                return;
            }

            const scrollYValue = scrollY.get();
            const scrollThreshold = 20;

            // If the user is scrolling down below 20 and the nav is visible, hide it.
            if (
                scrollYValue >= scrollThreshold &&
                scrollYValue > (scrollY?.getPrevious() || 0)
            ) {
                // Scrolling down
                if (backToTopEnabled) {
                    if (appDisplayMode.mobile) {
                        setIsNavVisible(false);
                    }
                } else {
                    setIsNavVisible(false);
                }

                // Close Menu drawer if it's open
                setActiveDirectory(undefined);
                // Close personal menu if it's open
                if (showPersonalMenu) {
                    expandedPersonalMenuActions.setFalse();
                }
                // Close the flag menu if it's open
                if (showFlagMenu) {
                    expandedFlagMenuActions.setFalse();
                }
            } else {
                const shouldShowNav =
                    (backToTopEnabled && appDisplayMode.mobile) ||
                    (!backToTopEnabled &&
                        (!showStickyNavAfterPosition ||
                            scrollYValue <= scrollThreshold ||
                            scrollYValue > showStickyNavAfterPosition));

                if (shouldShowNav) {
                    setIsNavVisible(true);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, [
        expandedPersonalMenuActions,
        scrollY,
        showPersonalMenu,
        showFlagMenu,
        expandedFlagMenuActions,
        backToTopEnabled,
        appDisplayMode.mobile,
        showStickyNavAfterPosition,
    ]);

    // Define the animation for the nav.
    const navAnimation = {
        y: debounceIsNavVisible ? 0 : '-100%',
        opacity: shouldFade
            ? 1 - ((scroll.top - fadeTopNavAfterPosition) / 100) * 3
            : 1,
    };

    //condition for overriding show top nav - moved here because useABTestIsOn has to be used within provider
    const guestHomeABTest = useABTestIsOn('guest-homepage');
    if (showGuestNav && guestHomeABTest) {
        return null;
    }

    return (
        <>
            <motion.div
                initial={{
                    y: isNavVisible ? 0 : '-100%', // When the page loads, if the user is not at the top of the page, hide the nav.
                    opacity: 1,
                }}
                className={css(S.TopNavigationWrapper)}
                transition={{ type: 'tween', duration: 0.2 }}
                {...(!menuDrawerOpen && {
                    animate: navAnimation,
                })}
                style={{
                    visibility: shouldFade ? 'hidden' : 'visible',
                }}
            >
                <TopNavigation
                    appType={'web'}
                    backgroundColorOnFloat={
                        isHomePage
                            ? theme.siteVariables.bodyBackground
                            : undefined
                    }
                    location={location}
                    env={env}
                    assetsBaseUrl={config.assetsBaseUrl}
                    appDisplayMode={appDisplayMode}
                    renderAnchorTag={renderAnchorCallback}
                    user={user}
                    notifications={notifications}
                    featureFlags={{
                        ecommerceEnabled,
                        shopNavEnabled,
                        searchPreFiliteringEnabled,
                        ecommerceActiveEnabled: appData.ecommerceActiveEnabled,
                        ba2NavEnabled: appData.ba2NavEnabled,
                    }}
                    minimalTopNav={minimalTopNav}
                    cart={{
                        data: shoppingCart ?? null,
                        cartPopupState,
                    }}
                    features={{ canCreateProfessional }}
                    logo={{
                        defaultShowFullLogo:
                            desktopDefaultShowFullLogo ||
                            mobileDefaultShowFullLogo,
                    }}
                    onPoll={() => {
                        //TODO: FIND A BETTER SOLUTION
                        //It seems as though the notifications is intercepting design board responses
                        //this is a temporary fix to stop the responses from hanging
                        if (minimalTopNav) return;
                        // This fetch is a hack to ensure that the endpoint is up before trying to run it
                        // through remix. useFetcher().load() can't be caught and handled.
                        // This might be caused by deployments.. not sure what else does it.
                        // Can be reproduced locally by killing the remix
                        // server and allowing the poll to continue on.
                        // Started a discussion here: https://github.com/remix-run/remix/discussions/6466
                        const fetchNotifications = async () => {
                            try {
                                // Check if endpoint is available
                                await fetch('/remix-api/dev/null');

                                // Load notifications if health check passes
                                notificationsFetcher.load(
                                    '/remix-api/user/notifications'
                                );
                            } catch (error) {
                                logger.error('Failed to fetch notifications', {
                                    error,
                                    context: 'TopNavigation.onPoll',
                                });
                            }
                        };

                        fetchNotifications();
                    }}
                    search={{
                        defaultSearchValue: currentSearch,
                        data: fetcher.data,
                        isActive: isSearchBarExpandedDesktop,
                        onChange: (_, searchInputValue) => {
                            search(searchInputValue);
                        },
                        onNavigate: (to, navigationType) => {
                            if (remixEnabled) {
                                navigate(to, {
                                    replace: navigationType === 'replace',
                                });
                                return;
                            }

                            // If we are legacy ecommerce page, remix = false. Topnav will run
                            if (typeof window !== 'undefined' && hydrated) {
                                window.location.href = to;
                                return;
                            }
                        },
                        onClick: (_, item) => {
                            if (!item) return;
                            const { params } = parseURL(
                                location.pathname + location.search
                            );

                            const category = item.category
                                ? item.category
                                : getString(params, 'category');

                            const newPath = generateURL('/search', {
                                category: category ? [category] : [],
                                search: item.search,
                            });
                            // Suggested categories can only do basic search against category title based on the search term.
                            // which means no other filers applied, e.g."shop" filter and it returns all categories that has items without other filters.
                            // To avoid display 0 result after clicking the suggested category, we need to get rid of all the other filters.

                            // But "shop" filter has user preference logic
                            // if there's no shop filter in the search param and user applied the filter before it will be add to the search param
                            // To prevent this behavior, here we force reset the user preference
                            let categoryLink = item.categoryLink ?? '';
                            if (
                                categoryLink &&
                                categoryLink.includes('products')
                            ) {
                                categoryLink = `${item.categoryLink}?${USER_PREFERENCE_KEY}=1`;
                            }

                            if (
                                typeof window !== 'undefined' &&
                                location.pathname.includes('/ecommerce-launch')
                            ) {
                                // Ecommerce launch -> legacy search, wait for hydration.
                                // Otherwise this can infinitely render
                                window.location.href = categoryLink || newPath;
                                return;
                            }
                            if (remixEnabled) {
                                return navigate(categoryLink || newPath);
                            }
                        },
                    }}
                    megaMenu={{
                        getFeaturedTile: (item, onClick, ref) =>
                            getFeaturedTile(
                                item,
                                renderAnchorTag,
                                onClick,
                                ref
                            ),
                        getAdFeaturedTile: (item, onClick, ref) =>
                            getAdFeaturedTile(
                                item,
                                onClick,
                                ref,
                                renderAnchorTag
                            ),
                        getAdForCurrentPath: () => {
                            return fetchMegaMenuAdForCategory(logger);
                        },
                        data:
                            !(
                                potmHeroEnabled &&
                                (!scroll || scroll.top < 50)
                            ) &&
                            appData.megamenu &&
                            activeDirectory
                                ? appData.megamenu[activeDirectory.directory]
                                : undefined,
                        onInitFetch: (directory) => {
                            setActiveDirectory({ directory });
                        },
                        onHover: (directory) => {
                            setActiveDirectory({ directory });
                        },
                        disabled: !remixEnabled,
                        defaultData:
                            ('megamenu' in appData && appData.megamenu) ||
                            undefined,
                    }}
                    subNavMenu={{
                        data: secondaryNavData,
                        directory:
                            activeDirectory?.directory ??
                            getDirectoryTypeByPathname(location.pathname),
                        onHover: (state) => {
                            if (!state) return;
                            setActiveDirectory(state);
                        },
                    }}
                    disableSlideAnimation={disableSlideAnimation}
                    categoryTree={{
                        data: categoryTreeData.data,
                        onNavigate: (to, navigationType) => {
                            navigate(to, {
                                replace: navigationType === 'replace',
                            });
                        },
                        onFetchCategoryTree:
                            categoryTreeData.onFetchCategoryTree,
                    }}
                    onMouseLeave={() => {
                        // reset subnav
                        setActiveDirectory({
                            directory: getDirectoryTypeByPathname(
                                location.pathname
                            ),
                        });
                    }}
                    onMenuDrawerOpen={(val) => {
                        setMenuDrawerOpen(val);
                    }}
                    interactionType={
                        isUsingTouch || !remixEnabled ? 'click' : 'hover'
                    }
                    transparent={showTransparentTopNav}
                    personalMenuState={[
                        showPersonalMenu,
                        expandedPersonalMenuActions,
                    ]}
                    flagMenuState={[showFlagMenu, expandedFlagMenuActions]}
                    showReseneSponsor={appData.showReseneSponsor}
                    deviceType={deviceType}
                >
                    {!!warningMessages?.length && (
                        <WarningBanner alerts={warningMessages} />
                    )}
                </TopNavigation>
                {showAddProfessional && (
                    <AddProfessionalModal
                        onClose={() => {
                            setShowAddProfessional.setFalse();
                        }}
                        isOpen={true}
                    />
                )}
            </motion.div>
            {!showTransparentTopNav && (
                <div className={css(S.TopNavigationSpacer)} />
            )}
        </>
    );
};

export default TopNavigationRemix;
