import type { StyleRule } from '@archipro-design/aria';
import { pxToRem } from '@archipro-design/aria';

export const WarningBannerContainer: StyleRule = ({ theme }) => ({
    width: '100%',
    minHeight: pxToRem(56),
    zIndex: theme.siteVariables.zIndexes.topNav,
    color: theme.siteVariables.colors.primitive.white,
});

export const WarningBannerContent: StyleRule = ({ theme }) => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    rowGap: pxToRem(16),
    padding: pxToRem(16),
    backgroundColor: theme.siteVariables.colors.danger[200],

    [theme.screen.tablet]: {
        width: `calc(100% - ${pxToRem(100)})`,
        margin: '0 auto',
    },
});

export const WarningBannerItem: StyleRule = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

export const WarningBannerIcon: StyleRule = ({ theme }) => ({
    width: pxToRem(24),
    height: pxToRem(24),
    marginRight: pxToRem(8),
    '& svg': {
        fill: theme.siteVariables.colors.primitive.white,
    },
});
