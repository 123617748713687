import { ReactComponent as Globe } from '../../../icons/svg/globe.svg';
import { UIConfigurationError } from '../../../exceptions/ui-configuration-error';
import type { HeaderCountryElement } from './Header';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';

import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from '../../../components/popover';
import { RoccoLink } from '../../../components/link/RoccoLink';

const _countrySelectTriggerVariants = cva('flex items-center gap-x-2', {
    variants: {
        color: {
            light: 'fill-white text-white',
            dark: 'fill-gray-900 text-gray-900',
        },
    },
    defaultVariants: {
        color: 'light',
    },
});

interface CountrySelectMenuProps
    extends VariantProps<typeof _countrySelectTriggerVariants> {
    currentCountryCode: string;
    countries: HeaderCountryElement[];
}

export const CountrySelectMenu = (props: CountrySelectMenuProps) => {
    const { color, currentCountryCode, countries } = props;
    const currentCountry = countries.find(
        country => country.code === currentCountryCode,
    );

    if (!currentCountry) {
        throw new UIConfigurationError(
            `Unknown country selected: ${currentCountryCode}`,
        );
    }

    return (
        <div className="w-10">
            <Popover>
                <PopoverTrigger>
                    <div className={_countrySelectTriggerVariants({ color })}>
                        <Globe className="size-6" />
                        <span className="cursor-pointer text-base decoration-2 underline-offset-8 hover:underline">
                            {currentCountry.code}
                        </span>
                    </div>
                </PopoverTrigger>
                <PopoverContent align="start" paddings="small">
                    <div className="py-0.5">
                        {countries.map(country => (
                            <RoccoLink
                                key={country.code}
                                to={country.url}
                                className="flex items-center space-x-2 group hover:bg-gray-100 py-2 px-5 rounded-lg cursor-pointer"
                            >
                                <div
                                    className="rounded-full size-2.5 bg-gray-900 border border-gray-900"
                                    style={{
                                        backgroundColor:
                                            currentCountryCode === country.code
                                                ? 'gray-900'
                                                : 'transparent',
                                    }}
                                ></div>
                                <span className="hover:text-gray-900">
                                    {country.name}
                                </span>
                            </RoccoLink>
                        ))}
                    </div>
                </PopoverContent>
            </Popover>
        </div>
    );
};
