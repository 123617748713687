import React, { useMemo } from 'react';
import { ReactComponent as CartIcon } from '../../../icons/svg/cart.svg';
import { Badge } from '../../../components/badge';
import { cva } from 'class-variance-authority';
import { VariantProps } from 'class-variance-authority';
import { cn } from '../../../utils/cn';
import { HoverCard, HoverCardContent } from '../../../components/hover-card';
import { HoverCardTrigger } from '../../../components/hover-card/hover-card-trigger';
import { HeaderCartInfo } from './Header';
import { ShoppingCartMenu } from './ShoppingCartMenu';
import { useAtom } from 'jotai';
import { isShoppingCartMenuOpenState } from '../states/ui-shopping-cart-menu';
const _cartIconVariants = cva('size-5', {
    variants: {
        fill: {
            dark: 'fill-gray-900',
            light: 'fill-gray-50',
        },
    },
    defaultVariants: {
        fill: 'dark',
    },
});

interface UserCartProps extends VariantProps<typeof _cartIconVariants> {
    onUserCartClick: () => void;
    cartInfo: HeaderCartInfo;
}

export const UserCart = (props: UserCartProps) => {
    const { fill, onUserCartClick, cartInfo } = props;
    const [isShoppingCartMenuOpen, setIsShoppingCartMenuOpen] = useAtom(
        isShoppingCartMenuOpenState,
    );
    const { cartCount } = cartInfo;

    const cartIconComponent = (
        <button className="relative -top-1" onClick={onUserCartClick}>
            <div className="absolute -right-1 -top-1 flex">
                {cartCount > 0 && (
                    <Badge
                        size="centeredCompact"
                        text={cartCount > 9 ? 'small' : 'default'}
                        color={fill === 'dark' ? 'default' : 'destructive'}
                    >
                        {cartCount > 9 ? '9+' : cartCount}
                    </Badge>
                )}
            </div>
            <div color="transparent" aria-label="Cart">
                <CartIcon
                    className={cn(_cartIconVariants({ fill }), 'size-6')}
                />
            </div>
        </button>
    );

    const shoppingCartMenu = useMemo(
        () => (
            <ShoppingCartMenu
                cartInfo={cartInfo}
                onUserCartClick={onUserCartClick}
            />
        ),
        [cartInfo, onUserCartClick],
    );

    const renderDesktopCartMenu = () => (
        <HoverCard open={isShoppingCartMenuOpen}>
            <HoverCardTrigger
                onMouseEnter={() => setIsShoppingCartMenuOpen(true)}
                aria-expanded={isShoppingCartMenuOpen}
                aria-haspopup="true"
                aria-label={`Shopping cart with ${cartCount} items`}
            >
                {cartIconComponent}
            </HoverCardTrigger>
            <HoverCardContent
                variant="cart"
                onMouseLeave={() => setIsShoppingCartMenuOpen(false)}
                role="dialog"
                aria-label="Shopping cart contents"
            >
                {shoppingCartMenu}
            </HoverCardContent>
        </HoverCard>
    );

    if (cartCount === 0) return null;

    return (
        <>
            <div className="md:block hidden">{renderDesktopCartMenu()}</div>
            <div className="md:hidden block">{cartIconComponent}</div>
        </>
    );
};
