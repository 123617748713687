import { cva, type VariantProps } from 'class-variance-authority';
import { ReactComponent as MenuIcon } from '../../../../icons/svg/menu.svg';
import { ReactComponent as LogoIcon } from '../../../../icons/svg/logo-icon.svg';
import { ReactComponent as LikeIcon } from '../../../../icons/svg/like.svg';
import { ReactComponent as UserIcon } from '../../../../icons/svg/user.svg';
import { ReactComponent as InboxIcon } from '../../../../icons/svg/message.svg';
import { ReactComponent as OrdersIcon } from '../../../../icons/svg/cart.svg';
import { ReactComponent as NotificationsIcon } from '../../../../icons/svg/bell.svg';
import { ReactComponent as LogoutIcon } from '../../../../icons/svg/logout.svg';
import { Badge } from '../../../../components/badge';
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from '../../../../components/popover';
import {
    Avatar,
    AvatarFallback,
    AvatarImage,
} from '../../../../components/avatar';
import { UserMenuElement } from './UserMenuElement';
import type { HeaderNavLinks, HeaderUserData } from '../Header';

export const _desktopUserMenuTriggerVariants = cva(
    'flex items-center gap-x-3',
    {
        variants: {
            color: {
                light: 'text-white',
                dark: 'text-gray-900',
            },
        },
        defaultVariants: {
            color: 'light',
        },
    },
);
const _desktopUserMenuIconVariants = cva('size-5', {
    variants: {
        color: {
            light: 'fill-white',
            dark: 'fill-gray-900',
        },
    },
    defaultVariants: {
        color: 'dark',
    },
});

interface DesktopUserMenuProps
    extends VariantProps<typeof _desktopUserMenuTriggerVariants> {
    userData: HeaderUserData;
    onLogout: () => void;
    additionalUserMenuItems?: HeaderNavLinks;
}

export const DesktopUserMenu = (props: DesktopUserMenuProps) => {
    const { color, userData, onLogout, additionalUserMenuItems } = props;

    const {
        avatarSrc = '',
        firstName,
        lastName = '',
        notificationsCount,
        professionals,
    } = userData;

    return (
        <div className="relative -top-2 hidden items-center md:flex">
            <Popover>
                <PopoverTrigger>
                    <div className={_desktopUserMenuTriggerVariants({ color })}>
                        <MenuIcon
                            className={_desktopUserMenuIconVariants({ color })}
                        />
                        <div className="relative">
                            <Avatar size="square40">
                                <AvatarImage src={avatarSrc} />
                                <AvatarFallback>
                                    {firstName[0]}
                                    {lastName[0]}
                                </AvatarFallback>
                            </Avatar>
                            {notificationsCount > 0 ? (
                                <div className="absolute -right-1 -top-1 flex">
                                    <Badge
                                        size="centeredCompact"
                                        text={
                                            notificationsCount > 9
                                                ? 'small'
                                                : 'default'
                                        }
                                        color={'dark'}
                                    >
                                        {notificationsCount > 9
                                            ? '9+'
                                            : notificationsCount}
                                    </Badge>
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                </PopoverTrigger>
                <PopoverContent paddings="small" align="end">
                    <div className="flex flex-col">
                        <div className="flex h-8 items-center px-2">
                            <span className="text-sm font-medium">
                                {firstName} {lastName}
                            </span>
                        </div>
                        <UserMenuElement
                            icon={
                                <UserIcon className="size-4.5 mx-auto stroke-gray-900" />
                            }
                            title="My Account"
                            to="/member/settings/edit-profile"
                            external={true}
                        />
                        <UserMenuElement
                            icon={<InboxIcon />}
                            title="Inbox"
                            to="/member/inbox"
                            external={true}
                            badgeCount={userData.inboxCount}
                        />
                        <UserMenuElement
                            icon={<LikeIcon />}
                            title="Design Boards"
                            to="/member/design-boards"
                            badgeCount={userData.designBoardCount}
                        />
                        <UserMenuElement
                            icon={<OrdersIcon />}
                            title="Orders"
                            to="/member/orders"
                            external={true}
                            badgeCount={userData.orderNotificationCount}
                        />
                        <UserMenuElement
                            icon={<NotificationsIcon />}
                            title="Notifications"
                            to="/member/notifications"
                            external={true}
                            badgeCount={userData.websiteNotificationCount}
                        />
                        {professionals && professionals.length > 0 ? (
                            <>
                                <div className="my-2.5 h-[0.0625rem] border-t-0 bg-gray-400"></div>
                                {professionals.map(professional => (
                                    <UserMenuElement
                                        key={professional.id}
                                        icon={
                                            <Avatar size="square20">
                                                <AvatarImage
                                                    src={professional.logo}
                                                />
                                                <AvatarFallback>
                                                    {professional.title[0]}
                                                </AvatarFallback>
                                            </Avatar>
                                        }
                                        title={professional.title}
                                        to={`/business/${professional.urlSegment}`}
                                        external={true}
                                    />
                                ))}
                            </>
                        ) : null}
                        <div className="my-2.5 h-[0.0625rem] border-t-0 bg-gray-400"></div>
                        <UserMenuElement
                            icon={<LogoIcon />}
                            title="List a Business"
                            to="/advertising"
                            external={true}
                        />
                        <div className="my-2.5 h-[0.0625rem] border-t-0 bg-gray-400"></div>
                        <UserMenuElement
                            icon={<LogoIcon />}
                            title="Home Design Evening"
                            to="/home-design-event"
                            external={true}
                        />
                        {additionalUserMenuItems &&
                        additionalUserMenuItems.links &&
                        additionalUserMenuItems.links.length > 0 ? (
                            <>
                                <div className="my-2.5 h-[0.0625rem] border-t-0 bg-gray-400"></div>
                                {additionalUserMenuItems.links.map(item => (
                                    <UserMenuElement
                                        key={item.title}
                                        icon={<LogoIcon />}
                                        title={item.title}
                                        {...(item.onClick
                                            ? { onClick: item.onClick }
                                            : { to: item.to })}
                                        external={item.external}
                                    />
                                ))}
                            </>
                        ) : null}
                        <div className="my-2.5 h-[0.0625rem] border-t-0 bg-gray-400"></div>
                        <UserMenuElement
                            icon={<LogoutIcon />}
                            title="Logout"
                            onClick={onLogout}
                        />
                    </div>
                </PopoverContent>
            </Popover>
        </div>
    );
};
