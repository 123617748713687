import type { LoaderFunctionArgs } from '@remix-run/node';
import { SortDirection } from 'generated/graphql';
import { getDesignBoardList } from '@modules-rocco/design-board/api/get-design-board-list.server';

export const ACTION_GET_DESIGN_BOARD_LIST = '/actions/get-design-board-list';

export const loader = async ({ request, context }: LoaderFunctionArgs) => {
    const url = new URL(request.url);

    return await getDesignBoardList(
        {
            limit: parseInt(url.searchParams.get('limit') || '2000'),
            offset: parseInt(url.searchParams.get('offset') || '0'),
            sortBy: {
                LastEdited: SortDirection.Desc,
            },
        },
        context
    );
};
