import type { Notifications } from '@archipro-website/top-navigation/src/lib/types';
import type {
    HeaderNavLinks,
    HeaderUserData,
} from '@rocco/ui/website-header/components/Header';
import { PROFILE_NAV } from '../../../utils/route-config';

/**
 * Generates header data for the current user including profile links and user information
 * @param user - The current user object
 * @param notifications - User's notification counts across different sections
 * @returns Object containing header user data and profile navigation links
 */
export const getUserHeaderData = (
    user:
        | {
              __typename: 'Me';
              FirstName: string;
              LastName: string;
              ProfileImage: string;
              OrderNotificationCount: number;
              Professionals?: Array<{
                  ID: number;
                  Title: string;
                  CompanyLogo: string;
                  URLSegment: string;
              }>;
          }
        | { __typename: 'Guest' },
    notifications: Notifications
): {
    headerUserData: HeaderUserData;
    profileLinks: HeaderNavLinks;
} => {
    // Initialize with guest/default values
    let headerUserData: HeaderUserData = {
        isAuthorized: false,
        firstName: 'Guest',
        notificationsCount: 0,
        professionals: [],
        orderNotificationCount: 0,
        inboxCount: 0,
        designBoardCount: 0,
        websiteNotificationCount: 0,
    };
    let profileLinks: HeaderNavLinks = PROFILE_NAV;

    // Only process authenticated users
    if (user.__typename === 'Me') {
        // Calculate total notifications across all notification sources
        const notificationsCount =
            notifications.website.count +
            (notifications.userInbox?.count || 0) +
            (notifications.designBoard.count || 0) +
            (notifications.companyInboxes?.reduce(
                (acc, inbox) => acc + (inbox.count || 0),
                0
            ) ?? 0) +
            (user.OrderNotificationCount || 0);
        // Transform user data into header format
        headerUserData = {
            isAuthorized: true,
            avatarSrc: user.ProfileImage,
            firstName: user.FirstName,
            lastName: user.LastName,
            notificationsCount,
            professionals:
                user.Professionals?.map((prof) => ({
                    id: prof.ID,
                    title: prof.Title,
                    logo: prof.CompanyLogo,
                    urlSegment: prof.URLSegment,
                    // Get unread count for this professional's inbox
                    unreads:
                        notifications.companyInboxes?.find(
                            (inbox) => inbox.id === prof.ID
                        )?.count ?? 0,
                })) ?? [],
            orderNotificationCount: user.OrderNotificationCount ?? 0,
            inboxCount: notifications.userInbox?.count ?? 0,
            designBoardCount: notifications.designBoard.count ?? 0,
            websiteNotificationCount: notifications.website.count ?? 0,
        };

        // Map notification counts to their respective pages
        const badgeCountMap: { [path: string]: number } = {
            '/member/inbox': notifications.userInbox?.count ?? 0,
            '/member/design-boards': notifications.designBoard.count ?? 0,
            '/member/notifications': notifications.website.count ?? 0,
            '/member/orders': user.OrderNotificationCount ?? 0,
        };

        // Add badge counts to profile navigation links
        profileLinks = {
            ...PROFILE_NAV,
            links: PROFILE_NAV.links.map((link) => ({
                ...link,
                badgeCount: badgeCountMap[link.to] ?? 0,
            })),
        };

        return { headerUserData, profileLinks };
    }

    return { headerUserData, profileLinks };
};
