import { useMatches } from '@remix-run/react';
import { z } from 'zod';

import type { SupportedCountry } from '../../../../../localisation/src/types';

interface LoaderData {
    warningMessages: string[];
}

const isLoaderData = (data: unknown): data is LoaderData => {
    const schema = z.object({ warningMessages: z.array(z.string()) });
    return schema.safeParse(data).success;
};

/**
 * Get the warning messages from the loader data of the route matches.
 * @returns A list of warning messages. Empty list if no warning messages.
 */
export const useWarningMessages = (): string[] => {
    const matches = useMatches();
    return matches.reduce<string[]>((messages, match) => {
        if (isLoaderData(match.data)) {
            for (const message of match.data.warningMessages) {
                if (!messages.includes(message)) {
                    messages.push(message);
                }
            }
        }
        return messages;
    }, []);
};

/**
 * Return a warning message when the user is viewing content from a different country.
 */
const countryContentWarning = (
    itemCountry: string,
    currentCountry: SupportedCountry
): string | null => {
    if (itemCountry === currentCountry) {
        return null;
    }
    return `You are viewing ${itemCountry} content on the ${currentCountry} domain.`;
};

/**
 * Generate warning messages from the given parameters, e.g. product not live or
 * warning AP Staff when viewing content from a different country.
 * @returns A list of warning messages. Empty list if no warning messages.
 */
export const createWarningMessages = (
    itemCountry: string,
    currentCountry: SupportedCountry,
    isPageNotLive?: boolean,
    pageNotLiveName?: string,
    isProfessionalDisabled?: boolean,
    permissions?: {
        showPageNotLiveWarning?: boolean;
        isAPStaff?: boolean;
    }
): string[] => {
    const warningMessages: string[] = [];

    if (isProfessionalDisabled) {
        warningMessages.push(
            'All pages for this company are disabled and only viewable & searchable by ArchiPro admins.'
        );
    }

    if (isPageNotLive && permissions?.showPageNotLiveWarning) {
        const pageName = pageNotLiveName ?? 'page';
        warningMessages.push(`This ${pageName} is not live.`);
    }

    if (itemCountry && currentCountry && permissions?.isAPStaff) {
        const countryWarning = countryContentWarning(
            itemCountry,
            currentCountry
        );
        if (countryWarning) {
            warningMessages.push(countryWarning);
        }
    }

    return warningMessages;
};
