import { HeaderCartInfo } from './Header';
import { Button } from '../../../components/button';
import { Badge } from '../../../components/badge';
import { Image } from '../../../components/image';
export const ShoppingCartMenu = ({
    cartInfo,
    onUserCartClick,
}: {
    cartInfo: HeaderCartInfo;
    onUserCartClick: () => void;
}) => {
    return (
        <div>
            <h2 className="text-base font-bold pb-4 pt-2 uppercase">
                Shopping Cart
            </h2>
            <div className="py-4 border-t border-gray-200 overflow-y-auto max-h-[50vh] scrollbar-hide">
                {cartInfo.items.map(item => (
                    <div
                        key={item.id}
                        className="flex items-center gap-x-4 h-20 p-2 hover:bg-gray-100 cursor-default"
                    >
                        <div className="w-16 h-16 overflow-hidden rounded-md">
                            <Image
                                src={item.thumbnail.desktop}
                                alt={item.title}
                                fit="contain"
                            />
                        </div>
                        <div className="flex flex-col justify-between h-full flex-1">
                            <h3 className="text-base font-medium line-clamp-2">
                                {item.title}
                            </h3>
                            <div className="flex items-between items-center w-full">
                                <p className="text-sm text-gray-500 flex-1">
                                    {item.price}
                                </p>
                                {item.isSample && (
                                    <div className="w-8 relative -top-1">
                                        <Badge
                                            size="centeredCompact"
                                            text="small"
                                            color="secondary"
                                        >
                                            <span className="text-xs text-gray-600">
                                                SAMPLE
                                            </span>
                                        </Badge>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="min-w-10 text-right font-medium">
                            x{item.quantity}
                        </div>
                    </div>
                ))}
            </div>
            <div className="flex justify-between py-2 font-medium">
                <p>Subtotal</p>
                <p>{cartInfo.itemTotal.ShortFormat}</p>
            </div>
            <div className="flex justify-between py-2 font-medium">
                <Button
                    size="default"
                    layoutMode="fillFlex"
                    onClick={onUserCartClick}
                >
                    Go To Cart
                </Button>
            </div>
        </div>
    );
};
