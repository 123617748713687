import { useCallback } from 'react';
import { Tracker } from '../../types';
import {
    DOWNLOAD_ATTR_PROFESSIONAL_TITLE,
    DOWNLOAD_ATTR_FILE_TITLE,
    DOWNLOAD_ATTR_PROFESSIONAL_ID,
    DOWNLOAD_ATTR_PRODUCT_ID,
    DOWNLOAD_ATTR_PROJECT_ID,
    DOWNLOAD_ATTR_FILE_ID,
} from '../../constants';
import { TrackedUser } from '../../trackers/google-tag-manager/googleTagManager';

export const useTrackDownloadClick = (
    trackerInstance: Tracker,
    user?: TrackedUser
) => {
    return useCallback(
        (target: HTMLElement, mouseButton: 0 | 1 | 2) => {
            const professionalTitle = target.getAttribute(
                DOWNLOAD_ATTR_PROFESSIONAL_TITLE
            );
            const professionalID = target.getAttribute(
                DOWNLOAD_ATTR_PROFESSIONAL_ID
            );
            const productID = target.getAttribute(DOWNLOAD_ATTR_PRODUCT_ID);
            const projectID = target.getAttribute(DOWNLOAD_ATTR_PROJECT_ID);
            const fileTitle = target.getAttribute(DOWNLOAD_ATTR_FILE_TITLE);
            const fileID = target.getAttribute(DOWNLOAD_ATTR_FILE_ID);

            if (!professionalTitle) {
                console.warn(
                    `Missing attribute: ${DOWNLOAD_ATTR_PROFESSIONAL_TITLE}. Tracking performance will be degraded.`
                );
                return;
            }
            if (!fileTitle) {
                console.warn(
                    `Missing attribute: ${DOWNLOAD_ATTR_FILE_TITLE}. Tracking performance will be degraded.`
                );
                return;
            }

            trackerInstance
                .log('DownloadsClick', {
                    url: new URL(window.location.href),
                    label: professionalTitle,
                    data: {
                        ExtraData: {
                            fileTitle,
                            MouseButton: mouseButton,
                        },
                        ...(professionalID && {
                            professionalID: parseInt(professionalID) || 0,
                        }),
                        ...(productID && {
                            productID: parseInt(productID) || 0,
                        }),
                        ...(projectID && {
                            projectID: parseInt(projectID) || 0,
                        }),
                        ...(fileID && {
                            fileID: parseInt(fileID) || 0,
                        }),
                        ...(fileTitle && {
                            fileTitle,
                        }),
                        memberID: user?.ID || 0,
                        ...(user?.__typename && {
                            memberType: user.__typename,
                        }),
                    },
                })
                .then(() => {})
                .catch(() => {});
        },
        [trackerInstance, user?.ID, user?.__typename]
    );
};
