import * as React from 'react';
import * as HoverCardPrimitive from '@radix-ui/react-hover-card';
import { cva, VariantProps } from 'class-variance-authority';
import { OmitStylesComponentProps } from '../../types';

const hoverCardContentVariants = cva('rocco-hover-card-content', {
    variants: {
        variant: {
            default:
                'z-50 w-64 rounded-md border bg-popover p-4 text-popover-foreground shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
            cart: 'z-50 w-100 rounded-md border bg-popover p-4 text-popover-foreground shadow-2xl outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
        },
    },
    defaultVariants: {
        variant: 'default',
    },
});

export const HoverCardContent = React.forwardRef<
    React.ElementRef<typeof HoverCardPrimitive.Content>,
    OmitStylesComponentProps<typeof HoverCardPrimitive.Content> &
        VariantProps<typeof hoverCardContentVariants>
>(({ align = 'center', sideOffset = 4, variant, ...props }, ref) => (
    <HoverCardPrimitive.Content
        ref={ref}
        align={align}
        sideOffset={sideOffset}
        className={hoverCardContentVariants({ variant })}
        {...props}
    />
));

HoverCardContent.displayName = HoverCardPrimitive.Content.displayName;
