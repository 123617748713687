import { useCallback, useEffect } from 'react';
import { useFetcher } from '@remix-run/react';
import { useAtom } from 'jotai';
import type { z } from 'zod';

import { useTracker } from '@archipro-website/tracker';
import type { SubmitActionResponse } from '~/routes/remix-api.enquiry.submit';
import { useUserLoggedIn } from '@modules/user';
import { trackSubmitCreateEnquiryRequest } from '@modules/tracking/util/trackSubmitCreateEnquiryRequest';
import { trackBrochureRequest } from '@modules/tracking/util/trackBrochureRequest';
import { trackProjectEnquire } from '@modules/tracking/util/trackProjectEnquiry';
import { enquiryFormSessionSchema } from '../validation';
import {
    autoSubmitDelayAtom,
    enquiryAutoSubmitAtom,
    enquirySessionAtom,
} from './use-enquiry';

export function useEnquiryAutoSubmit() {
    const { isLoggedIn, user } = useUserLoggedIn();
    const tracker = useTracker();
    const fetcher = useFetcher<SubmitActionResponse>({ key: 'enquiry' });
    const [enquirySession] = useAtom(enquirySessionAtom);
    const [enquiryAutoSubmit, setEnquiryAutoSubmit] = useAtom(
        enquiryAutoSubmitAtom
    );
    const [autoSubmitDelay] = useAtom(autoSubmitDelayAtom);

    /**
     * TODO: Merge with `submitEnquiry` in `useEnquiry` when old enquiry form is retired.
     * @see {@link ./use-enquiry.ts}
     */
    const autoSubmitEnquiry = useCallback(
        (values: z.infer<typeof enquiryFormSessionSchema>) => {
            setEnquiryAutoSubmit(false);
            const payload = {
                name: values.name,
                email: isLoggedIn && user.Email ? user.Email : values.email,
                phoneNumber: values.phoneNumber,
                suburbPostcode: values.postcode.value?.region ?? '',
                locationResponse: JSON.stringify(values.postcode.value),
                branchId: values.branchId ?? null,
                message: values.message,
                siteTreeId: values.siteTreeId,
                shareDetails: 'true',
                newEnquiryForm: '1',
            };

            fetcher.submit(payload, {
                method: 'POST',
                action: '/remix-api/enquiry/submit',
            });

            tracker.log('EnquirySubmit', {
                url: new URL(window.location.href),
                label: values.professional.Title,
            });
            trackSubmitCreateEnquiryRequest(tracker, {
                source: values.source,
                isAutoSubmit: true,
            });
            if (
                values.directoryType === 'professional' ||
                values.directoryType === 'product'
            ) {
                trackBrochureRequest(tracker, {
                    type: values.directoryType,
                    region: payload.suburbPostcode,
                });
            } else if (
                values.directoryType === 'project' &&
                values.relatedItem
            ) {
                trackProjectEnquire(tracker, {
                    category: values.relatedItem.category,
                    step: 2,
                    project: values.relatedItem.title,
                    professional: values.professional.Title,
                    region: payload.suburbPostcode,
                });
            }
            if (values.source != 'EnquiryModal') {
                tracker.log('OnPageEnquirySend', {
                    url: new URL(window.location.href),
                    targetTracker: 'archiproTracker',
                });
            }
        },
        [isLoggedIn, user, fetcher, tracker, setEnquiryAutoSubmit]
    );

    useEffect(() => {
        // Automatically submit enquiry from saved session after login.
        if (
            !enquiryAutoSubmit ||
            !enquirySession ||
            !isLoggedIn ||
            autoSubmitDelay
        ) {
            return;
        }
        const result = enquiryFormSessionSchema.safeParse(enquirySession);
        if (!result.success) {
            return;
        }
        autoSubmitEnquiry(result.data);
    }, [
        isLoggedIn,
        enquirySession,
        enquiryAutoSubmit,
        autoSubmitDelay,
        autoSubmitEnquiry,
    ]);

    // TODO: Curerntly, the success/error handling is done in `useEnquiry`, it
    // does not happen in auto submit which matches the old enquiry form behaviour.
    // When the old enquiry form is retired, unify the form submission and success/error
    // handling.
}
